import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit
} from "@angular/core";
import { DomSanitizer, SafeStyle, SafeValue } from "@angular/platform-browser";
import { DesignText } from "@sata/cdg-api";
import { TranslationStore } from "@smallstack/i18n-components";
import { Subscription } from "rxjs";
import { DesignStore } from "../DesignStore";
import { getFontSize } from "../functions";
import { GunCanvasConfig, GunCanvasImageEditingMode } from "./GunGame";
import { Vector2 } from "./Vector2";

export interface Layer {
  id: string;
  movable: boolean;
  index: number;
  label: string;
  labelKey: string;
  visible: boolean;
  deletable: boolean;
  imageUrl: SafeValue;
  draggable: boolean;
  selected: boolean;
}

interface LayerData {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  angle?: number;
  src?: any;
  text?: {
    text: string;
    fontSize: number;
    fontFace: DesignText.FontFaceEnum;
    fontStyle: DesignText.FontStyleEnum;
    color: string;
  };
  border?: number;
}

@Component({
  selector: "gun-component",
  templateUrl: "./gun.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GunComponent implements OnDestroy, OnInit {
  public get layers() {
    if (!this.layerData) return [];
    return this.layerData.map((imgData) => this.layerDataToStyle(imgData));
  }

  public get scaleZoom(): string {
    return `scale(${this.zoom})`;
  }

  public get offsetLeft() {
    let p = this.cameraOffset.x + (this.cameraOffsetOffset ? this.cameraOffsetOffset.x : 0);
    p *= this.zoom;
    const ps = `${p < 0 ? "-" : "+"} ${p < 0 ? -p : p}px`;
    return this.domSanitizer.bypassSecurityTrustStyle(`calc(50%  ${ps})`);
  }

  public get offsetTop() {
    let p = this.cameraOffset.y + (this.cameraOffsetOffset ? this.cameraOffsetOffset.y : 0);
    p *= this.zoom;
    const ps = `${p < 0 ? "-" : "+"} ${p < 0 ? -p : p}px`;
    return this.domSanitizer.bypassSecurityTrustStyle(`calc(50%  ${ps})`);
  }

  public get cursorStyle() {
    return this.mouseDownCoordinate ? "grabbing" : "grab";
  }

  private get editing(): boolean {
    return this.mode !== GunCanvasImageEditingMode.none;
  }

  @Input()
  public store: DesignStore;

  public backgroundColor: string;
  public cfg: GunCanvasConfig;
  public selectedLayerOffset: { x: number; y: number; width: number; height: number; angle: number };
  public mouseDownCoordinate: { x: number; y: number };
  public cameraOffset: { x: number; y: number } = { x: 0, y: 0 };
  public cameraOffsetOffset: { x: number; y: number } = { x: 0, y: 0 };
  public layerData: LayerData[];
  public zoom: number;
  public isPinching: boolean;
  public initialZoom: number;
  public initialRotation: number;

  public highlight: {
    style: { [index: string]: string };
    transform: SafeStyle;
    topLeftText: string;
    topRightText: string;
    bottomLeftText: string;
  };

  public showTextLegend = false;

  public laserung: Layer;
  public anbauteile: Layer;

  private subscription: Subscription;
  private mode: GunCanvasImageEditingMode;

  constructor(
    private domSanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private el: ElementRef<HTMLDivElement>,
    private zone: NgZone,
    private translationStore: TranslationStore
  ) {}

  public ngOnInit(): void {
    this.subscription = this.store.configChanges$.subscribe((cfg) => {
      this.updateImages(cfg);
      this.updateLayers(cfg);
    });

    this.zone.runOutsideAngular(() => {
      this.el.nativeElement.onmousemove = (evt: MouseEvent): any => {
        this.mouseMove(evt);
      };
    });
    this.cdr.detectChanges();
  }

  public toggleVisible(l: Layer) {
    if (l === this.laserung) this.store.toggleLaserung();
    else if (l === this.anbauteile) this.store.toggleAnbauteile();
  }

  public onPinchStart(evt) {
    this.isPinching = true;
    this.initialZoom = this.store.config.zoom;
    this.mouseDown(evt);
  }

  public async onPinchEnd(evt) {
    this.isPinching = false;
    await this.mouseUp();
    this.initialRotation = undefined;
  }

  public onPinch(evt) {
    // if no layer is selected
    if (this.cfg.selectedLayerId === undefined) {
      this.store.changeZoom(this.initialZoom * evt.scale);
    } else if (this.selectedLayerOffset) {
      // scale
      const selectedImage = this.store.getSelectedLayer();
      const newWidth = selectedImage.width * evt.scale;
      const newHeight = selectedImage.height * evt.scale;
      this.selectedLayerOffset.width = newWidth - selectedImage.width;
      this.selectedLayerOffset.height = newHeight - selectedImage.height;

      // rotate
      if (this.initialRotation === undefined) this.initialRotation = evt.rotation;
      const rotation = this.initialRotation - evt.rotation;
      this.selectedLayerOffset.angle = -rotation;

      this.updateImages(this.cfg);
    }
  }

  public mouseDown(evt) {
    if (evt) {
      const e = this.toLocalCoordinate(evt);
      if (e) {
        if (this.cfg.selectedLayerId && this.editing) {
          this.selectedLayerOffset = { x: 0, y: 0, width: 0, height: 0, angle: 0 };
        }
        this.mouseDownCoordinate = e;
        this.cameraOffsetOffset = { x: 0, y: 0 };
      }
    }
  }

  public mouseMove(evt) {
    const e = this.toLocalCoordinate(evt);
    if (e) {
      if (this.mouseDownCoordinate) {
        const selectedImage = this.store.getSelectedLayer();
        if (this.cfg.editingMode === GunCanvasImageEditingMode.move) {
          this.selectedLayerOffset.x = e.x - this.mouseDownCoordinate.x;
          this.selectedLayerOffset.y = e.y - this.mouseDownCoordinate.y;
        } else if (this.cfg.editingMode === GunCanvasImageEditingMode.scale) {
          // The image center is the base of the scaling calculations
          const center = new Vector2(selectedImage.x, selectedImage.y);

          // The distance between where the mouse was pressed down and the center of the image
          const initialDistance = center.distanceTo(
            new Vector2(this.mouseDownCoordinate.x, this.mouseDownCoordinate.y)
          );

          // the current distance of the mouse and the center of the image
          const distanceNow = center.distanceTo(new Vector2(e.x, e.y));

          // how do those two distances relate?
          const f = distanceNow / initialDistance;
          const newWidth = selectedImage.width * f;
          const newHeight = selectedImage.height * f;
          // console.log(`Center:${center.x.toFixed(2)},${center.y.toFixed(2)} Mouse down:${this.mouseDownCoordinate.x.toFixed(0)},${this.mouseDownCoordinate.y.toFixed(0)} (${initialDistance}), Mouse now:${e.x.toFixed(0)},${e.y.toFixed(0)} (${distanceNow}),  f:${f.toFixed(2)}`);
          this.selectedLayerOffset.width = newWidth - selectedImage.width;
          this.selectedLayerOffset.height = newHeight - selectedImage.height;
        } else if (this.cfg.editingMode === GunCanvasImageEditingMode.rotate) {
          const center = new Vector2(selectedImage.x, selectedImage.y);
          const v = new Vector2(this.mouseDownCoordinate.x, this.mouseDownCoordinate.y).minus(center);
          const v2 = new Vector2(e.x, e.y).minus(center);
          const a = v.angleInDegrees() - v2.angleInDegrees();
          this.selectedLayerOffset.angle = a;
        } else {
          // panning
          this.cameraOffsetOffset.x = e.x - this.mouseDownCoordinate.x;
          this.cameraOffsetOffset.y = e.y - this.mouseDownCoordinate.y;
        }
        // console.log("mouse move triggers update of images");
        this.updateImages(this.cfg);
      }
    }
  }

  public async mouseUp() {
    let s;
    if (
      this.cfg.editingMode !== GunCanvasImageEditingMode.none &&
      this.cfg.selectedLayerId &&
      this.selectedLayerOffset
    ) {
      const selectedLayer = this.store.getSelectedLayer();
      s = {
        x: selectedLayer.x + this.selectedLayerOffset.x,
        y: selectedLayer.y + this.selectedLayerOffset.y,
        width: selectedLayer.width + this.selectedLayerOffset.width,
        height: selectedLayer.height + this.selectedLayerOffset.height,
        angle: selectedLayer.angle + this.selectedLayerOffset.angle
      };
      if (s.angle > 360) s.angle -= 360;
      if (s.angle < 0) s.angle += 360;
    } else if (this.cameraOffset && this.cameraOffsetOffset) {
      // panning
      // this is quite offsetting
      this.store.changeCameraOffset(
        this.cameraOffset.x + this.cameraOffsetOffset.x,
        this.cameraOffset.y + this.cameraOffsetOffset.y
      );
    }
    delete this.mouseDownCoordinate;
    delete this.selectedLayerOffset;
    delete this.cameraOffsetOffset;
    if (s) {
      await this.store.updateLayerPosition(this.cfg.selectedLayerId, s);
    }
    this.cdr.detectChanges();
  }

  public async mouseLeave() {
    await this.mouseUp();
  }

  public mouseEnter(evt) {
    if (evt.buttons === 1) {
      this.mouseDown(evt);
    }
  }
  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      delete this.subscription;
    }
  }

  public wereDoneHere() {
    this.store.setSelectedLayer(this.store.getSelectedLayer().id);
    this.store.changeMode(GunCanvasImageEditingMode.none);
  }

  private updateLayers(cfg: GunCanvasConfig) {
    this.laserung = {
      movable: false,
      draggable: false,
      index: 0,
      labelKey: "@@sata.gundesigner.layers.component.laseringlayer",
      label: null,
      visible: cfg.showLasering,
      deletable: false,
      imageUrl: null,
      id: null,
      selected: false
    };
    this.anbauteile = {
      movable: false,
      draggable: false,
      index: 1,
      labelKey: "@@sata.gundesigner.layers.component.addonlayer",
      label: null,
      visible: cfg.showAnbauteile,
      deletable: false,
      imageUrl: null,
      id: null,
      selected: false
    };
  }

  private toLocalCoordinate(e): { x: number; y: number } {
    const rect = e.target.getBoundingClientRect();
    let ex = e.clientX;
    let ey = e.clientY;
    if (e && e.changedTouches instanceof Array && e.changedTouches.length === 1) {
      ex = e.changedTouches[0].clientX;
      ey = e.changedTouches[0].clientY;
    } else if (e.changedPointers instanceof Array && e.changedPointers.length === 1) {
      ex = e.changedPointers[0].clientX;
      ey = e.changedPointers[0].clientY;
    }
    const x = ex - rect.left; // x position within the element.
    const y = ey - rect.top; // y position within the element.

    return {
      x: (x - e.target.clientWidth / 2 - this.cameraOffset.x) / this.cfg.zoom,
      y: (y - e.target.clientHeight / 2 - this.cameraOffset.y) / this.cfg.zoom
    };
  }

  private updateHighlight() {
    const img = this.store.getSelectedLayer();
    if (img && this.editing) {
      const w = img.width + (this.selectedLayerOffset ? this.selectedLayerOffset.width : 0);
      const h = img.height + (this.selectedLayerOffset ? this.selectedLayerOffset.height : 0);
      const x = img.x + (this.selectedLayerOffset ? this.selectedLayerOffset.x : 0);
      const y = img.y + (this.selectedLayerOffset ? this.selectedLayerOffset.y : 0);
      let a = img.angle + (this.selectedLayerOffset ? this.selectedLayerOffset.angle : 0);
      if (a > 360) a -= 360;
      if (a < 0) a += 360;
      this.highlight = {
        transform: this.domSanitizer.bypassSecurityTrustStyle(`translate(-50%,-50%) rotate(${a}deg)`),
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        style: {
          position: "absolute",
          left: "0px",
          top: "0px",
          width: "100%",
          height: "100%",
          "transform-origin": "center center"
        }
      };

      if (this.mode === GunCanvasImageEditingMode.move) {
        if (img.text === undefined)
          this.highlight.topLeftText = this.translationStore.translateByKey("sata.gundesigner.guncomponent.moveimage");
        else
          this.highlight.topLeftText = this.translationStore.translateByKey("sata.gundesigner.guncomponent.movetext");
        this.highlight.bottomLeftText = `x: ${Math.round(x)}, y: ${Math.round(y)}`;
      } else if (this.mode === GunCanvasImageEditingMode.scale) {
        this.highlight.topLeftText = this.translationStore.translateByKey("sata.gundesigner.guncomponent.scale");
        this.highlight.bottomLeftText = `w: ${Math.round(w)} / h: ${Math.round(h)}`;
      } else if (this.mode === GunCanvasImageEditingMode.rotate) {
        this.highlight.topLeftText = this.translationStore.translateByKey("sata.gundesigner.guncomponent.rotate");
        this.highlight.bottomLeftText = `a: ${Math.round(a)}°`;
      }

      if (img.text !== undefined) {
        this.showTextLegend = true;
        this.highlight.topRightText = this.translationStore.translateByKey("sata.gundesigner.textfield.hinttext");
      } else {
        this.highlight.topRightText = "";
        this.showTextLegend = false;
      }
    } else {
      delete this.highlight;
    }
  }

  private layerDataToStyle(lyr: LayerData) {
    const t = "translate(-50%,-50%) " + (lyr.angle ? `rotate(${lyr.angle}deg)` : "");
    const isItalic =
      lyr.text &&
      [DesignText.FontStyleEnum.BoldItalic, DesignText.FontStyleEnum.RegularItalic].indexOf(lyr.text.fontStyle) !== -1;
    let fontWeight = "normal";
    if (
      lyr.text &&
      [DesignText.FontStyleEnum.Bold, DesignText.FontStyleEnum.BoldItalic].indexOf(lyr.text.fontStyle) !== -1
    ) {
      fontWeight = "bold";
    }
    let ff = lyr.text ? `${lyr.text.fontFace}` : "";
    if (lyr.text && lyr.text.fontFace && lyr.text.fontFace === DesignText.FontFaceEnum.PtSansWeb) {
      ff = "PtSansWeb";
    }
    const r: any = {
      style: {
        width: "600px",
        height: "600px",
        position: "absolute",
        left: `${lyr.x || 0}px`,
        top: `${lyr.y || 0}px`,
        "transform-origin": "center center",
        color: lyr.text ? lyr.text.color : undefined,
        "font-family": ff || "",
        "font-style": isItalic ? "italic" : "",
        "font-weight": fontWeight,
        "font-size": `${lyr.text ? lyr.text.fontSize : 10}px`,
        "text-align": "center",
        "line-height": `${lyr.height}px`
      },
      transform: this.domSanitizer.bypassSecurityTrustStyle(t),
      src: lyr.src,
      text: lyr.text
    };
    if (lyr.border) {
      r.style["border-color"] = `rgba(255, 255, 255, ${lyr.border})`;
      r.style["border-width"] = "10000px";
      r.style["border-style"] = "solid";
    }
    if (lyr.width || lyr.height) {
      r.style.width = `${lyr.width}px`;
      r.style.height = `${lyr.height}px`;
    }
    return r;
  }

  private updateImages(cfg: GunCanvasConfig) {
    if (cfg && cfg.configuration && cfg.design) {
      this.mode = cfg.editingMode;
      this.cfg = cfg;
      this.zoom = cfg.zoom;

      this.backgroundColor = cfg.design ? `${cfg.design.color}` : "#ffffff";
      this.layerData = [];
      const side = cfg.visibleSide === "right" ? "rechts" : "links";
      this.cameraOffset.x = cfg.cameraOffset.x;
      this.cameraOffset.y = cfg.cameraOffset.y;
      this.layerData.push({
        src: `${cfg.configuration.assetPrefix}/assets/overlays/${cfg.design.productCode}_pistole_${side}.png`,
        border: 1.0
      });
      const selectedLayer = this.store.getSelectedLayer();

      cfg.design.sides[cfg.visibleSide].layers
        .filter((img) => cfg.hiddenImageIds.indexOf(img.id) === -1) // only layers that are not hidden
        // .filter((lyr) => !cfg.selectedLayerId || cfg.selectedLayerId === lyr.id) // if a layer is selected show only that one
        .map((lyr) => {
          const l: LayerData = {
            x: lyr.x,
            y: lyr.y,
            height: lyr.height,
            width: lyr.width,
            angle: lyr.angle
          };
          if (cfg.selectedLayerId && cfg.selectedLayerId === lyr.id && this.selectedLayerOffset) {
            l.x += this.selectedLayerOffset.x;
            l.y += this.selectedLayerOffset.y;
            l.width += this.selectedLayerOffset.width;
            l.height += this.selectedLayerOffset.height;
            l.angle += this.selectedLayerOffset.angle;
          }

          if (lyr.image) {
            l.src = lyr.image.previewUrl || lyr.image.url;
          } else {
            l.text = { ...lyr.text, fontSize: getFontSize(lyr, l.width) };
          }
          return l;
        })
        .forEach((element) => this.layerData.push(element));

      // text area "underlay"
      if (selectedLayer && selectedLayer.text)
        this.layerData.push({
          src: `${cfg.configuration.assetPrefix}/assets/overlays/textarea_${side}.png`,
          border: 0.7
        });

      this.updateHighlight();

      // overlays

      if (selectedLayer) {
        this.layerData.push({
          src: `${cfg.configuration.assetPrefix}/assets/overlays/${cfg.design.productCode}_pistole_${side}-outline.png`,
          border: 0.7
        });
      } else {
        this.layerData.push({
          src: `${cfg.configuration.assetPrefix}/assets/overlays/${cfg.design.productCode}_pistole_${side}.png`,
          border: 1.0
        });
        const anbauteilIsSchwarz = cfg.design.productVariant.options.VM200 === "schwarz";
        const anbauteilIsHvlp = cfg.design.productVariant.options.VM500.indexOf("HVLP") !== -1;
        const hasDigitalanzeige = cfg.design.productVariant.options.VM010.indexOf("Digital") === 0;
        if (cfg.showAnbauteile) {
          this.layerData.push({
            src: `${cfg.configuration.assetPrefix}/assets/overlays/${cfg.design.productCode}_anbauteile_${
              anbauteilIsSchwarz ? "schwarz" : "chrom"
            }_${anbauteilIsHvlp ? "hvlp" : "rp"}_${side}.png`
          });
          if (hasDigitalanzeige) {
            this.layerData.push({
              src: `${cfg.configuration.assetPrefix}/assets/overlays/${cfg.design.productCode}_digital-${side}${
                side === "rechts" ? (anbauteilIsSchwarz ? "_schwarz" : "_chrom") : ""
              }.png`
            });
          }
        }
        if (cfg.showLasering) {
          this.layerData.push({
            src: `${cfg.configuration.assetPrefix}/assets/overlays/${cfg.design.productCode}_laserung_${side}_${
              hasDigitalanzeige ? "digital" : "standard"
            }${side === "rechts" ? (anbauteilIsHvlp ? "_hvlp" : "_rp") : ""}.png`
          });
        }
      }
    }
    this.cdr.detectChanges();
  }
}
