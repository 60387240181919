import { DragDropModule } from "@angular/cdk/drag-drop";
import { LayoutModule } from "@angular/cdk/layout";
import { registerLocaleData } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import localeDE from "@angular/common/locales/de";
import localeEN from "@angular/common/locales/en";
import localeES from "@angular/common/locales/es";
import localeFR from "@angular/common/locales/fr";
import localeIT from "@angular/common/locales/it";
import { APP_INITIALIZER, Injectable, NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule } from "@angular/forms";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatChipsModule } from "@angular/material/chips";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ApiModule, BASE_PATH } from "@sata/cdg-api";
import { AxiosApiClient, InternationalizationApi } from "@smallstack/axios-api-client";
import { SmallstackCommonModule } from "@smallstack/common-components";
import {
  LocaleService,
  MaterialDialogNotifier,
  MaterialSnackbarNotifier,
  SmallstackI18nModule,
  TranslationLoader,
  TranslationStore
} from "@smallstack/i18n-components";
import { loadCommon, loadCommonActions } from "@smallstack/language-packs";
import { ClientProductService } from "@smallstack/product-components";
import * as Hammer from "hammerjs";
import { ColorPickerModule } from "ngx-color-picker";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { PIMProductOptionPriceComponent } from "./base/pim/pim-product-option-price/pim-product-option-price.component";
import { PIMProductPriceComponent } from "./base/pim/pim-product-price/pim-product-price.component";
import { PIMProductVariantPriceComponent } from "./base/pim/pim-product-variant-price/pim-product-variant-price.component";
import { GunComponent } from "./designer/canvas/gun.component";
import { ConfigurationComponent } from "./designer/configuration.component";
import { DesignLockedComponent } from "./designer/design-locked/design-locked.component";
import { DesignerComponent } from "./designer/designer.component";
import { GoogleAnalyticsService } from "./designer/ga.service";
import { ImageLibraryComponent } from "./designer/image-library.component";
import { ImagesAndTextComponent } from "./designer/images-and-text.component";
import { LayersComponent } from "./designer/layers.component";
import { MobileBottomSheetComponent } from "./designer/mobile-bottom-sheet.component";
import { PreviewComponent } from "./designer/preview.component";
import { PriceBoxComponent } from "./designer/pricebox.component";
import { SubmitDesignDialogComponent } from "./designer/submit-design-dialog.component";
import { TextDetailComponent } from "./designer/text-detail.component";
import { WizardComponent } from "./designer/wizard.component";
import { ZoomComponent } from "./designer/zoom.component";
import { ContestService } from "./services/contest.service";
import { MagentoStoreService } from "./services/magento-store.service";

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  public overrides = {
    rotate: {
      direction: Hammer.DIRECTION_ALL,
      enable: false
    },
    pinch: {
      direction: Hammer.DIRECTION_ALL,
      enable: true
    },
    pan: {
      direction: Hammer.DIRECTION_ALL,
      requireFailure: ["rotate, pinch"]
    }
  } as any;
}

registerLocaleData(localeDE);
registerLocaleData(localeEN);
registerLocaleData(localeIT);
registerLocaleData(localeFR);
registerLocaleData(localeES);

@NgModule({
  declarations: [
    DesignerComponent,
    ConfigurationComponent,
    WizardComponent,
    ImageLibraryComponent,
    ImagesAndTextComponent,
    TextDetailComponent,
    LayersComponent,
    GunComponent,
    ZoomComponent,
    SubmitDesignDialogComponent,
    PreviewComponent,
    PIMProductPriceComponent,
    PIMProductVariantPriceComponent,
    PIMProductOptionPriceComponent,
    MobileBottomSheetComponent,
    PriceBoxComponent,
    DesignLockedComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HammerModule,
    FlexLayoutModule,
    LayoutModule,
    AppRoutingModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSliderModule,
    MatButtonModule,
    MatInputModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatTabsModule,
    MatCardModule,
    MatDividerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatFormFieldModule,
    ColorPickerModule,
    DragDropModule,
    MatTooltipModule,
    MatMenuModule,
    MatDialogModule,
    MatSnackBarModule,
    MatChipsModule,
    MatProgressBarModule,
    BrowserAnimationsModule,
    MatBottomSheetModule,
    HttpClientModule,
    ApiModule,
    SmallstackI18nModule,
    SmallstackCommonModule
  ],
  providers: [
    MaterialSnackbarNotifier,
    MaterialDialogNotifier,
    GoogleAnalyticsService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    },
    { provide: BASE_PATH, useValue: environment.sataApiUrl },
    {
      provide: AxiosApiClient,
      useFactory: () => {
        return new AxiosApiClient({
          apiUrl: environment.smallstackApiUrl,
          resellerId: "smallstack",
          tenantId: environment.smallstackTenantId
        });
      }
    },
    ClientProductService,
    LocaleService,
    TranslationStore,
    {
      provide: APP_INITIALIZER,
      useFactory:
        (
          localeService: LocaleService,
          translationStore: TranslationStore,
          axiosApiClient: AxiosApiClient,
          translationLoader: TranslationLoader
        ) =>
        async () => {
          await localeService.getAllLocales();
          await localeService.detectLocale();
          const translations = await AxiosApiClient.loadAllPages((pageNumber: number) =>
            axiosApiClient
              .get(InternationalizationApi)
              .getTranslations({ page: pageNumber, size: 1000 })
              .then((res) => res.data)
          );
          translationStore.addValues(translations);
          translationLoader.register([loadCommon, loadCommonActions]);
        },
      deps: [LocaleService, TranslationStore, AxiosApiClient, TranslationLoader],
      multi: true
    },
    MagentoStoreService,
    ContestService,
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: (magentoStoreService: MagentoStoreService) => async () => {
        if (environment.contest === false) await magentoStoreService.load();
      },
      deps: [MagentoStoreService]
    }
  ],
  bootstrap: [DesignerComponent]
})
export class AppModule {
  constructor(translationStore: TranslationStore) {
    translationStore.addJsonTranslations(["de_de", "en_us"], {
      sata: { gundesigner: { designer: { component: { loadingdesign: ["Lade...", "Loading..."] } } } }
    });
  }
}
