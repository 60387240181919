import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { HashLocationStrategy } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Event, NavigationEnd, Router } from "@angular/router";
import { CommonService, Design, DesignService, DesignStatusEvent } from "@sata/cdg-api";
import { AxiosApiClient, InternationalizationApi } from "@smallstack/axios-api-client";
import { LocaleService, NotificationService, TranslationStore } from "@smallstack/i18n-components";
import { Subscription } from "rxjs";
import { environment } from "../../environments/environment";
import { PRODUCT_CODES } from "../app.constants";
import { ContestService } from "../services/contest.service";
import { MagentoStoreService } from "../services/magento-store.service";
import { GunCanvasConfig, GunCanvasImageEditingMode } from "./canvas/GunGame";
import { ConfigurationComponent } from "./configuration.component";
import { DesignLockedComponent } from "./design-locked/design-locked.component";
import { DesignStore } from "./DesignStore";
import { GoogleAnalyticsService } from "./ga.service";
import { ImagesAndTextComponent } from "./images-and-text.component";
import { MobileBottomSheetComponent, MobileBottomSheetData } from "./mobile-bottom-sheet.component";
import { PreviewComponent } from "./preview.component";
import { SubmitDesignDialogComponent } from "./submit-design-dialog.component";

@Component({
  selector: "app-root",
  templateUrl: "./designer.component.html",
  styleUrls: ["./designer.component.scss"],
  providers: [HashLocationStrategy, { provide: HashLocationStrategy, useClass: HashLocationStrategy }],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DesignerComponent implements OnInit, OnDestroy {
  get GunCanvasImageEditingMode() {
    return GunCanvasImageEditingMode;
  }

  get requiredAssets() {
    return this.assetPrefix
      ? DesignStore.REQUIRED_ASSETS.map((url) =>
          this.domSanitizer.bypassSecurityTrustResourceUrl(`${this.assetPrefix}${url}`)
        )
      : [];
  }

  public visibleSide: "left" | "right" = "left";
  public networkActivity: string;
  public networkProgress: number;
  public loadingDesignStatus = "loading";
  public designStatus: string;
  public assetPrefix: string;
  public screenTooSmall = false;
  public editable: boolean;
  public zoom: number;
  public mode: GunCanvasImageEditingMode = GunCanvasImageEditingMode.none;
  public selectedImageId: string;
  public designId: string;
  public store: DesignStore;
  public offline: boolean;
  public productCode: string;
  public locales: string[];

  public tooltipDownloadDesign: string;
  public tooltipCopyLink: string;
  public tooltipResetDesign: string;
  private offlinePopupShown = false;
  private resizeEventListener: (event: any) => any;
  private dialogRef: MatDialogRef<any, any>;
  private storeSubscription: Subscription;

  private submitDialogRef: MatDialogRef<any>;

  constructor(
    public breakpointObserver: BreakpointObserver,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private analyticsService: GoogleAnalyticsService,
    private bottomSheet: MatBottomSheet,
    private designService: DesignService,
    private commonService: CommonService,
    private axiosApiClient: AxiosApiClient,
    private magentoStoreService: MagentoStoreService,
    private notificationService: NotificationService,
    public contestService: ContestService,
    private localeService: LocaleService,
    private translationStore: TranslationStore
  ) {}

  public async ngOnInit() {
    if (this.isIncompatibleBrowser()) {
      this.notificationService.popup.warn(
        this.translationStore.translateByKey("sata.gundesigner.incompatiblebrowser.title", { showMissingKey: true }),
        this.translationStore.translateByKey("sata.gundesigner.incompatiblebrowser.message", { showMissingKey: true })
      );
      this.analyticsService.event("system", "incompatibleBrowser");
    }
    this.breakpointObserver
      .observe(["(min-width:1420px) and (min-height:700px)"])
      .subscribe((state: BreakpointState) => {
        this.screenTooSmall = !state.matches;
        if (this.store) {
          this.store.config.screenTooSmall = this.screenTooSmall;
        }
        if (this.screenTooSmall) this.analyticsService.event("system", "screenTooSmall");
      });
    this.router.events.subscribe(async (event: Event) => {
      if (event instanceof NavigationEnd) {
        const storeCode: string = this.activatedRoute.snapshot.queryParams.storeCode;
        let productCode: string = this.activatedRoute.snapshot.queryParams.productCode;
        if (!productCode) productCode = PRODUCT_CODES.SATAJET_5000;
        this.productCode = productCode;
        if (!storeCode && environment.contest === false) {
          this.magentoStoreService.gotoDefaultMagentoStorePage();
        } else {
          const designId = this.activatedRoute.snapshot.queryParams.designId;
          await this.initialize(storeCode, designId, productCode);
          this.analyticsService.ga("send", "pageview", "?storeCode=" + storeCode + "&productCode=" + productCode);
        }
      }
    });
    this.localeService.currentLocale$.subscribe(() => {
      this.tooltipDownloadDesign = this.translationStore.translateByKey(
        "sata.gundesigner.toolbar.downloaddesigntemplate"
      );
      this.tooltipCopyLink = this.translationStore.translateByKey("sata.gundesigner.toolbar.copylink");
      this.tooltipResetDesign = this.translationStore.translateByKey("sata.gundesigner.toolbar.resetdesign");
    });
    this.locales = (await this.axiosApiClient.get(InternationalizationApi).getLocales()).data.elements.map(
      (l) => l.iso
    );

    // show design locked screen if design is not in created mode
    this.store.designChanges$.subscribe((design: Design) => {
      if (design && design.latestStatus.status !== DesignStatusEvent.StatusEnum.Created) {
        this.dialog.open(DesignLockedComponent, { disableClose: true, width: "500px" });
      }
    });
  }

  public ngOnDestroy(): void {
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
      delete this.storeSubscription;
    }
  }

  public changeMode(newMode: GunCanvasImageEditingMode | string): void {
    if (this.mode === newMode) newMode = GunCanvasImageEditingMode.none;
    this.store.changeMode(newMode as GunCanvasImageEditingMode);
  }

  public copyDesignUrl() {
    this.analyticsService.event("userClickAction", "saveDesign");
    const val = window.location.href;
    const selBox = document.createElement("textarea");
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
    this.notificationService.popup.info(
      this.translationStore.translateByKey("common.notification.title.info"),
      this.translationStore.translateByKey(
        this.screenTooSmall ? "sata.gundesigner.copylink.successmobile" : "sata.gundesigner.copylink.success"
      )
    );
  }

  public getImageUrl(imageFileName: string): any {
    return this.domSanitizer.bypassSecurityTrustUrl(`${this.assetPrefix}/${imageFileName}`);
  }

  public openDialog() {
    this.analyticsService.event("userClickAction", "showPreview");
    this.dialog.open(PreviewComponent, { width: "650px", height: "800px", data: this.store });
  }

  public openWizard() {
    this.dialog.open(ConfigurationComponent, { width: "100%", height: "100%", maxWidth: "100%", data: this.store });
  }

  public openLayers() {
    this.dialogRef = this.dialog.open(ImagesAndTextComponent, {
      width: "100%",
      height: "100%",
      maxWidth: "100%",
      data: this.store
    });
  }

  public openBottomSheet() {
    this.bottomSheet.open(MobileBottomSheetComponent, { data: { store: this.store } as MobileBottomSheetData });
  }

  public async resetDesign() {
    await this.store.resetDesign();
  }

  public sendEvent(eventName: string) {
    this.analyticsService.event("userClickAction", eventName);
  }

  private async initialize(storeCode: string, designId: string, productCode: string) {
    this.designId = designId;
    this.store = new DesignStore(
      storeCode,
      productCode,
      async (dId) => {
        await this.router.navigate([], {
          queryParams: {
            storeCode,
            designId: dId
          }
        });
      },
      this.designService,
      this.commonService,
      this.notificationService,
      this.contestService,
      this.translationStore
    );
    await this.store.init();

    this.store.setSmallScreen(this.screenTooSmall);
    let resizeTaskId;
    this.resizeEventListener = (evt) => {
      if (resizeTaskId !== null) {
        clearTimeout(resizeTaskId);
      }

      resizeTaskId = setTimeout(() => {
        resizeTaskId = null;
        this.store.windowResized(document.documentElement.clientWidth, document.documentElement.clientHeight);
      }, 50);
    };
    window.addEventListener("resize", this.resizeEventListener);

    this.store.configChanges$.subscribe((cfg: GunCanvasConfig) => {
      if (cfg) {
        this.visibleSide = cfg.visibleSide;
        this.loadingDesignStatus = cfg.loadingDesignStatus;
        this.networkActivity = cfg.networkActivity;
        if (this.networkActivity && this.dialogRef !== undefined) this.dialogRef.close();
        this.networkProgress = cfg.networkProgress * 100;
        this.designStatus = cfg.design ? cfg.design.latestStatus.status : undefined;
        if (this.designStatus !== undefined && this.designStatus === "submitting") {
          if (!this.submitDialogRef) {
            this.submitDialogRef = this.dialog.open(SubmitDesignDialogComponent, {
              closeOnNavigation: false,
              disableClose: true,
              width: "450px",
              data: {
                title: this.translationStore.translateByKey(
                  "sata.gundesigner.designer.component.statussummary.submitted"
                ),
                message: this.translationStore.translateByKey(
                  "sata.gundesigner.designer.component.statussummary.processing"
                )
              }
            });
          }
        }
        this.assetPrefix = cfg.configuration ? cfg.configuration.assetPrefix : undefined;
        this.editable = cfg.design && cfg.design.latestStatus.status === DesignStatusEvent.StatusEnum.Created;
        this.zoom = cfg.zoom;
        this.mode = cfg.editingMode;
        this.offline = cfg.offline;
        if (this.offline && this.offlinePopupShown === false) {
          this.offlinePopupShown = true;
          this.notificationService.popup.warn(
            this.translationStore.translateByKey("gundesigner.offline.title"),
            this.translationStore.translateByKey("gundesigner.offline.message"),
            () => (this.offlinePopupShown = false)
          );
        }
        this.selectedImageId = cfg.selectedLayerId;
        if (cfg.design && cfg.design.productCode) this.productCode = cfg.design.productCode;
      }
      this.cdr.detectChanges();
    });
    this.store.windowResized(document.documentElement.clientWidth, document.documentElement.clientHeight);
    await this.store.loadDesign(this.designId);
  }

  private isIncompatibleBrowser(): boolean {
    if (/MSIE/i.test(navigator.userAgent)) {
      return true;
    }

    if (/rv:11.0/i.test(navigator.userAgent)) {
      return true;
    }

    if (/Edge\/\d./i.test(navigator.userAgent)) {
      return true;
    }
  }
}
