import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { PriceDto, PriceZoneDto, ProductDto, ProductVariantDto } from "@smallstack/axios-api-client";
import { LocaleService } from "@smallstack/i18n-components";
import { ClientProductService } from "@smallstack/product-components";

@Component({
  selector: "pim-product-variant-price",
  templateUrl: "./pim-product-variant-price.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PIMProductVariantPriceComponent implements OnChanges {
  @Input()
  public product: ProductDto;

  @Input()
  public productVariant: ProductVariantDto;

  @Input()
  public priceZone: PriceZoneDto;

  public price: number;
  public locale: string;
  public currency: string;

  constructor(private clientProductService: ClientProductService, localeService: LocaleService) {
    localeService.currentLocale$.subscribe((lang: string) => (this.locale = lang));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.product && this.productVariant && this.priceZone) {
      const prices = this.clientProductService.calculatePrices(
        this.product,
        this.productVariant as any,
        this.product.options
      );
      if (!prices) throw new Error("no prices found for product & variant combination!");
      const price: PriceDto = prices.find((p) => p.priceZoneId === this.priceZone.id);
      if (!price) throw new Error("no price found for price zone: " + this.priceZone.id);
      this.currency = this.priceZone.currency;
      this.price = price.value / 100;
    }
  }
}
