import { ChangeDetectionStrategy, Component, Input, OnChanges } from "@angular/core";
import { Product } from "@sata/cdg-api";
import { PriceZoneDto } from "@smallstack/axios-api-client";
import { LocaleService } from "@smallstack/i18n-components";

@Component({
  selector: "pim-product-price",
  templateUrl: "./pim-product-price.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PIMProductPriceComponent implements OnChanges {
  @Input()
  public product: Product;

  @Input()
  public priceZone: PriceZoneDto;

  public locale: string;
  public price: number;

  constructor(localeService: LocaleService) {
    localeService.currentLocale$.subscribe((locale: string) => (this.locale = locale));
  }

  public ngOnChanges(): void {
    if (this.product && this.priceZone) {
      this.price = this.product.prices.find((p) => p.priceZoneId === this.priceZone.id).value / 100;
    }
  }
}
