import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { DesignText } from "@sata/cdg-api";
import { Subscription } from "rxjs";
import { GunCanvasConfig } from "./canvas/GunGame";
import { DesignStore } from "./DesignStore";
import { getFontSize, isHVLP } from "./functions";

interface LayerData {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  angle?: number;
  src?: any;
  text?: {
    text: string;
    fontSize: number;
    fontFace: DesignText.FontFaceEnum;
    fontStyle: DesignText.FontStyleEnum;
    color: string;
  };
  border?: number;
}

@Component({
  selector: "preview",
  templateUrl: "./preview.component.html",
  styleUrls: ["./preview.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreviewComponent implements OnInit, OnDestroy {
  public get backgroundUrl() {
    return this.domSanitizer.bypassSecurityTrustUrl(
      `${this.assetPrefix}/assets/preview/${this.productCode}_preview_bg_mask_${this.visibleSide}.png`
    );
  }

  public get addOnUrl() {
    return this.domSanitizer.bypassSecurityTrustUrl(
      `${this.assetPrefix}/assets/preview/${this.productCode}_preview_anbauteile_${this.visibleSide}_${
        this.black ? "black" : "chrom"
      }_${this.hvlp ? "hvlp" : "rp"}.png`
    );
  }

  public get pistoleUrl() {
    if (this.digital)
      return this.domSanitizer.bypassSecurityTrustUrl(
        `${this.assetPrefix}/assets/preview/${this.productCode}_preview_pistole_digital_${this.visibleSide}.png`
      );
    else
      return this.domSanitizer.bypassSecurityTrustUrl(
        `${this.assetPrefix}/assets/preview/${this.productCode}_preview_pistole_${this.visibleSide}.png`
      );
  }

  public get laserungUrl() {
    return this.domSanitizer.bypassSecurityTrustUrl(
      `${this.assetPrefix}/assets/preview/${this.productCode}_preview_laserung_${this.visibleSide}_${
        this.digital ? "digital" : "standard"
      }${this.visibleSide === "right" ? (this.hvlp ? "_hvlp" : "_rp") : ""}.png`
    );
  }

  public get layers() {
    const r = this.layerData.map((imgData) => this.layerToStyle(imgData));
    return r;
  }

  public layerData: LayerData[];
  public visibleSide: "left" | "right";
  public backgroundColor: string;
  private subscr: Subscription;
  private assetPrefix: string;
  private hvlp: boolean;
  private digital: boolean;
  private black: boolean;
  private cfg: GunCanvasConfig;
  private productCode: string;

  constructor(
    public domSanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<PreviewComponent>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public store: DesignStore
  ) {}

  public ngOnDestroy(): void {
    if (this.subscr) {
      this.subscr.unsubscribe();
      delete this.subscr;
    }
  }

  public ngOnInit(): void {
    this.subscr = this.store.configChanges$.subscribe((cfg) => {
      if (cfg) {
        this.assetPrefix = cfg && cfg.configuration ? cfg.configuration.assetPrefix : undefined;
        this.productCode = cfg.design.productCode;
        this.hvlp = isHVLP(cfg.design);
        this.black = cfg.design.productVariant.options.VM200 === "schwarz";
        this.digital = cfg.design.productVariant.options.VM010.indexOf("Digital") === 0;
        this.backgroundColor = cfg.design.color;
        if (!this.visibleSide) this.visibleSide = cfg.visibleSide;
        this.cfg = cfg;
        this.updateImages();
        this.cdr.detectChanges();
      }
    });
  }

  public setVisibleSide(nSide: "left" | "right") {
    this.visibleSide = nSide;
    this.updateImages();
    this.cdr.detectChanges();
  }

  public getImageUrl(imageFileName: string): any {
    return this.domSanitizer.bypassSecurityTrustUrl(`${this.assetPrefix}/${imageFileName}`);
  }

  public closePreview(): void {
    this.dialogRef.close();
  }

  public isIncompatibleBrowser(): boolean {
    if (/MSIE/i.test(navigator.userAgent)) {
      return true;
    }

    if (/rv:11.0/i.test(navigator.userAgent)) {
      return true;
    }

    if (/Edge\/\d./i.test(navigator.userAgent)) {
      return true;
    }
  }

  private layerToStyle(lyr: LayerData) {
    const w = lyr.width; // ? img.width * 1000 / 600 : undefined;
    const h = lyr.height; // ? img.height * 1000 / 600 : undefined;
    const t = "translate(-50%,-50%) " + (lyr.angle ? `rotate(${lyr.angle}deg)` : "");
    const isItalic =
      lyr.text &&
      [DesignText.FontStyleEnum.BoldItalic, DesignText.FontStyleEnum.RegularItalic].indexOf(lyr.text.fontStyle) !== -1;
    let fontWeight = "normal";
    if (
      lyr.text &&
      [DesignText.FontStyleEnum.Bold, DesignText.FontStyleEnum.BoldItalic].indexOf(lyr.text.fontStyle) !== -1
    ) {
      fontWeight = "bold";
    }
    let ff = lyr.text ? `${lyr.text.fontFace}` : "";
    if (lyr.text && lyr.text.fontFace && lyr.text.fontFace === DesignText.FontFaceEnum.PtSansWeb) {
      ff = "PtSansWeb";
    }
    const r: any = {
      style: {
        position: "absolute",
        left: `${lyr.x || 0}px`,
        top: `${lyr.y || 0}px`,
        "transform-origin": "center center",
        color: lyr.text ? lyr.text.color : undefined,
        "font-family": ff || "",
        "font-style": isItalic ? "italic" : "",
        "font-weight": fontWeight,
        "font-size": `${lyr.text ? lyr.text.fontSize : 10}px`,
        "text-align": "center",
        "line-height": `${lyr.height}px`
      },
      transform: this.domSanitizer.bypassSecurityTrustStyle(t),
      src: lyr.src,
      text: lyr.text
    };
    if (lyr.border) {
      r.style["border-color"] = `rgba(255, 255, 255, ${lyr.border})`;
      r.style["border-width"] = "10000px";
      r.style["border-style"] = "solid";
    }
    if (w || h) {
      r.style.width = `${w}px`;
      r.style.height = `${h}px`;
    }
    return r;
  }

  private updateImages() {
    this.backgroundColor = this.cfg.design ? this.cfg.design.color : "#ffffff";

    this.layerData = this.cfg.design.sides[this.visibleSide].layers.map((lyr) => {
      const l: LayerData = {
        x: lyr.x,
        y: lyr.y,
        height: lyr.height,
        width: lyr.width,
        angle: lyr.angle
      };
      if (lyr.image) {
        l.src = lyr.image.url;
      } else {
        l.text = { ...lyr.text, fontSize: getFontSize(lyr, l.width) };
      }
      return l;
    });
  }
}
